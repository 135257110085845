// Imports
// ------
import styled, { css } from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { breakup, Footer, Div, Em, List, ListItem } from '@tackl';

// Exports
// ------
export const Jacket = styled(Footer)``;

export const PostFooter = styled(Div)` padding: 3.6rem 0; `;

export const PreFooter = styled(Div)` padding: 3.6rem 0; `;

const sharedMarquee = css`
    transition: all 1s ease;
    position: relative;
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow-y: visible;
    margin-bottom: ${props => props.noMargin ? `0` : `3.6rem`};
`;

export const MarqueeJacket = styled.div`
    ${sharedMarquee}
`;

export const Content = styled.div`
    display: inline-block;

    svg {
        margin-left: 12rem;
        margin-right: 2rem;
        width: auto;
        height: 3vh;

        ${breakup.medium` height: 2vh; `}
        ${breakup.large` height: 3.6vh; `}
    }
`;

export const Text = styled.span`
    transition: all 0.3s ease;
    display: inline;
    font-family: ${props => props.theme.body};
    font-size: 4vh;
    font-weight: 200;
    color: ${props => props.theme.bc1};

    ${breakup.medium` font-size: 3.6vw; `}

    ${breakup.large` font-size: 5vh; `}
`;

export const ConversationJacket = styled.div`
    transition: all 1s ease;
    display: inline-flex;
    padding: 3.6rem 0;
    align-items: center;

    ${Text} {
        display: inline-block;
        margin-right: 3.6rem !important;
        max-width: 60rem;
        white-space: normal;

        font-size: 1.4rem;
        line-height: 2.4rem;

        ${breakup.medium`
            font-size: 2rem;
            line-height: 2.4rem;
        `}

        em {
            font-family: ${props => props.theme.serif};
            font-style: italic;
            font-weight: 501;
        }
    }

    svg {
        position: relative;
        display: inline-block;
        margin-right: 3.6rem;
        min-width: 4rem;
    }

    button { margin-right: 3.6rem; }
`;

export const InteractiveMarqueeJacket = styled.div`
    ${sharedMarquee}
    margin: 0;

    span { margin: 0 !important; }

    &:hover {
        ${ConversationJacket} {
            pointer-events: none;
            filter: blur(1.2rem);
        }
    }
`;

export const MarqueeLink = styled(AniLink)`
    position: relative;
    display: block;
`;

export const LocationsJacket = styled.div`
    display: inline-flex;
    align-items: center;
`;

export const Loc = styled(Em)`
    transition: all 0.3s ease-in-out;
    margin: 0 2.4rem;
    color: ${props => props.theme.bc1};
    opacity: 0.4;
`;

export const MobileButtonJacket = styled.div`
    margin-top: 2.4rem;
`;

export const LinkList = styled(List)``;

export const LinkItem = styled(ListItem)`
    display: inline-block;
    width: 50%;
    text-align: center;
    margin-bottom: 2.4rem;

    ${breakup.medium` text-align: left; `}
    
    ${breakup.large`
        width: auto;
        margin-right: 2.4rem;
    `}
`;

export const Link = styled(AniLink)`
    position: relative;
    display: inline-block;

    font-family: ${props => props.theme.body};
    font-size: 1.4rem;
    text-transform: uppercase;

    &:hover {
        cursor: pointer;
        &:after { transform: scaleY(1.1); }
    }

    &:after {
        content: '';
        position: absolute;
        z-index: 2;
        top: 0; left: 0; right: 0; bottom: 0;
        background: ${props => props.theme.bc1};
        mix-blend-mode: difference;
        transform: scaleY(0);
        transform-origin: center bottom;
        transition: all .25s ease;
    }
`;

export const Copy = styled(Em)`
    opacity: 0.4;
    text-align: center;

    ${breakup.medium` text-align: right; `}
`;