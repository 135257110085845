// Imports
// ------
import React from 'react';
import RobotWhite from '@images/robot-white.gif';
import RobotBlack from '@images/robot-black.gif';
import DataConversation from './Data/Conversation';
import DataDeck from './Data/Deck';
import DataProposal from './Data/Proposal';
import DataExpert from './Data/Expert';
import DataMember from './Data/Member';
import { Chat } from '@states/chat';
import { lightDarkMode } from '@states/darkmode';
import { observer } from 'mobx-react';

// Styles
// ------
import {
    Jacket,
    StaticForm,
    CForm,
    Submit
} from './styles';

// Component
// ------
class ChatWindow extends React.PureComponent {
    constructor(props) {
        super(props);
        
        this.form = React.createRef();
        this.staticForm = React.createRef();
        this.convoForm = React.createRef();

        this.state = {
            formName: "",
            formAction: "",
            status: "",
        }
    }

    activateForm = () => {
        const { ConversationalForm } = require("conversational-form");
        const form = this.staticForm.current;

        const cfInstance = new ConversationalForm({
            formEl: this.staticForm.current,
            context: this.convoForm.current,
            robotImage: lightDarkMode.isDark ? RobotBlack : RobotWhite,
            userImage: false,
            preventAutoFocus: true,
            showProgressBar: false,
            submitCallback: () => {
                const data = new FormData(form);
                const xhr = new XMLHttpRequest();
                xhr.open(form.method, form.action);
                xhr.setRequestHeader("Accept", "application/json");
                xhr.onreadystatechange = () => {
                if (xhr.readyState !== XMLHttpRequest.DONE) return;
                    if (xhr.status === 200) {
                        cfInstance.addRobotChatResponse("Thank you. We will be in touch shortly.");

                        setTimeout(() => { Chat.isActive = false }, 5000);

                        setTimeout(() => {
                            [
                                Chat.requestDeck,
                                Chat.requestProposal, 
                                Chat.requestExpert, 
                                Chat.requestConversation, 
                                Chat.requestIndividual
                            ] = false;

                            [Chat.personName, Chat.personImage ] = '';
                        }, 6000);
                    } else {
                        cfInstance.addRobotChatResponse("Something went wrong! 😭");
                    }
                };
                xhr.send(data);
            },
        });
    }

    formCheck = () => {
        if(Chat.requestConversation === true) {
            this.setState({
                formName: "contact",
                formAction: "https://formspree.io/xeqrnrwg"
            });
        }

        if(Chat.requestDeck === true) {
            this.setState({
                formName: "deck request",
                formAction: "https://formspree.io/moqkrkzw"
            });
        }

        if(Chat.requestProposal === true) {
            this.setState({
                formName: "proposal request",
                formAction: "https://formspree.io/xknqyqaq"
            });
        }

        if(Chat.requestExpert === true) {
            this.setState({
                formName: "expert request",
                formAction: "https://formspree.io/xpzybyej"
            });
        }

        if(Chat.requestIndividual === true) {
            this.setState({
                formName: "team member chat",
                formAction: "https://formspree.io/xvowlwnv"
            });
        }
    }

    componentDidMount() {
        this.formCheck();
        this.activateForm();
    }

    render() {
        let sharedFormProps = {
            name: this.state.formName,
            method: "POST",
            action: this.state.formAction,
        }

        return (
            <Jacket ref={this.form}>
                {/* Static form that builds the interactive conversational form */}
                <StaticForm ref={this.staticForm} {...sharedFormProps}>
                    {/* Honeypot spam filtering */}
                    <input type="text" name="_gotcha" style={{ display:"none" }} />

                    {/* Now we need the form data, which is dynamically driven depending on state selections. */}
                    {Chat.requestConversation && <DataConversation />}
                    {Chat.requestDeck && <DataDeck />}
                    {Chat.requestProposal && <DataProposal />}
                    {Chat.requestExpert && <DataExpert />}
                    {Chat.requestIndividual && <DataMember />}

                    {/* The static form also includes the submit button */}
                    <Submit type="submit">Submit</Submit>
                </StaticForm>

                {/* Now for the conversational form, everything above is transpiled and used within the plugin */}
                <CForm ref={this.convoForm} {...sharedFormProps}>
                    {/* Honeypot spam filtering */}
                    <input type="text" name="_gotcha" style={{ display:"none" }} />
                </CForm>
            </Jacket>
        );
    }

    componentDidUpdate() {
        this.formCheck();
        this.activateForm();
    }
}

export default observer(ChatWindow);