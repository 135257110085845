// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup } from '@tackl';

// Exports
// ------
export const Jacket = styled.div`
    position: relative;
    z-index: 12;
    
    display: none;
    align-items: center;
    justify-content: flex-end;
    
    ${props => props.isMobile && css` display: flex; `}

    ${props => props.isDesktop && css`
        ${breakup.large`
            display: flex;
            position: absolute;
            top: 3.6rem;
            right: 2.4rem;
        `}
    `}
`;

export const Close = styled.button`
    position: relative;
    display: inline-block;
    width: ${props => props.isDesktop ? `3.2rem` : `2.4rem`};
    height: ${props => props.isDesktop ? `3.2rem` : `2.4rem`};
    background: none;

    &:before, &:after {
        content: '';
        position: absolute;
        top: 50%; left: 50%;
        width: ${props => props.isDesktop ? `3.2rem` : `2.4rem`};
        height: 1px;
        background: ${props => props.theme.bc1};
        transform-origin: center center;
    }

    &:before {
        transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
    }
`;