// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup } from '@tackl';

// Exports
// ------
export const Jacket = styled.div(props => css`
    position: absolute;
    top: 0; bottom: 0;
    z-index: 0;
    display: flex;
    max-width: 100%;
    min-width: 100%;
    width: 100vw;
    height: auto;
    overflow: hidden;

    ${breakup.large`
        position: relative;
        height: 100%;
        border-top: 1px solid ${props.theme.bc1};
        border-bottom: 1px solid ${props.theme.bc1};
        border-left: 1px solid ${props.theme.bc1};
    `}
`)

const sharedFormStyles = css`
    border-radius: 0;
    overflow: hidden;
`;

export const StaticForm = styled.form`
    ${sharedFormStyles}
    position: absolute;
    z-index: 1;
    top: 0; left: 0; right: 0; bottom: 0;
    opacity: 0;
`;

export const CForm = styled.form(props => css`
    ${sharedFormStyles}
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;

    &:before {
        content: '';
        position: absolute;
        top: 0; left: 0; right: 0; bottom: 0;
        background: ${props.theme.bc2};
    }

    .conversational-form * {
        cursor: auto;
    }

    #conversational-form {
        z-index: 999999!important;
        background: ${props.theme.bc2};

        &:before { display: none; }

        .conversational-form-inner {
            padding: 0;
        }

        cf-input-control-elements {
            padding: 0 1.2rem;
        }

        cf-chat {
            padding-bottom: 1.2rem;

            .scrollableInner {
                padding: 4rem 2.4rem;
                margin: 0 auto;
                width: 100%;

                ${breakup.large` max-width: 80%; `}
                ${breakup.xxlarge` max-width: 60%; `}
            }
        }

        .user {
            p.show {
                display: block;
                box-sizing: border-box !important;
                border: 1px solid${props.theme.bc1} !important;
                border-radius: 3rem;
                border-top-right-radius: 0px;
                background: ${props.theme.bc1};
                color: ${props.theme.bc2};
            }
        }

        .robot {
            p.show {
                display: block;
                box-sizing: border-box !important;
                border: 1px solid ${props.theme.bc1} !important;
                border-radius: 3rem;
                border-top-left-radius: 0px;
                background: ${props.theme.bc2};
                color: ${props.theme.bc1};
            }
        }

        cf-input-button.cf-input-button div.cf-icon-progress {
            background-size: 4rem 4rem;
        }

        cf-chat-response {
            thumb {
                background-color: transparent;
                border-radius: 0;
                background-position: top center;
            }

            &.user {
                padding-right: 0;

                thumb { display: none; }
            }
        }

        p.show {
            padding: 1.2rem 2.4rem;
            border-radius: 0;
            font-size: 1.8rem;
            line-height: 3.2rem;
            color: ${props.theme.bc2};
        }

        cf-input {
            background: transparent;
            overflow: hidden;
            margin-bottom: 0;

            input,
            textarea {
                border-radius: 0;
                border-top: 1px solid ${props.theme.bc1};
                background: ${props.theme.bc2};
                color: ${props.theme.bc1};

                &:hover {
                    color: ${props.theme.bc1};

                    &::placeholder {
                        color: ${props.theme.bc1};
                    }
                }
            }

            cf-input-button {
                border-radius: 0;
                border-top: 1px solid ${props.theme.bc1};
                background: ${props.theme.bc2};

                ${breakup.large` border-right: 1px solid ${props.theme.bc1}; `}

                .cf-input-icons { transform: rotate(90deg); }
            }

            &.hide-input {
                cf-input-button {
                    display: none;
                }
            }
        }
    }
`);

export const Input = styled.input``;

export const TextArea = styled.textarea``;

export const Submit = styled.button``;