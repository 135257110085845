// Imports
// ------
import React from 'react';
import { Chat } from '@states/chat';
import { formQuestions } from '@states/formQuestions';

// Styles
// ------
import {
    Input
} from '../../styles';

// Component
// ------
const IndustryQ = () => (
    <fieldset cf-questions={`Thank you, {name}. Within luxury, what is your primary focus?`}>
        <label htmlFor="Fashion">Fashion &amp; Jewelry
            <input type="radio" name="industry-selection" id="Fashion" value="Fashion & Jewelry" />
        </label>
        <label htmlFor="Mobility">Mobility
            <input type="radio" name="industry-selection" id="Mobility" value="Mobility" />
        </label>
        <label htmlFor="Beauty">Beauty
            <input type="radio" name="industry-selection" id="Beauty" value="Beauty" />
        </label>
        <label htmlFor="Technology">Technology
            <input type="radio" name="industry-selection" id="Technology" value="Technology" />
        </label>
        <label htmlFor="Hospitality">Hospitality
            <input type="radio" name="industry-selection" id="Hospitality" value="Hospitality" />
        </label>
        <label htmlFor="ConsumerGoods">Consumer Goods
            <input type="radio" name="industry-selection" id="ConsumerGoods" value="Consumer Goods" />
        </label>
        <label htmlFor="Manufacturing">Manufacturing
            <input type="radio" name="industry-selection" id="Manufacturing" value="Manufacturing" />
        </label>
        <label htmlFor="FineArts">Fine Arts
            <input type="radio" name="industry-selection" id="FineArts" value="Fine Arts" />
        </label>
        <label htmlFor="GeneralInterest">General Interest
            <input type="radio" name="industry-selection" id="GeneralInterest" value="General Interest" />
        </label>
    </fieldset>
);

const SolutionQ = () => (
    <fieldset cf-questions={`And what type of digital solution is most important to you?`}>
        <label htmlFor="exp">Experience &amp; Product Design (UX/UI)
            <input type="radio" name="solution-selection" id="exp" value="Experience and Product Strategy" />
        </label>
        <label htmlFor="ent">Web / Software Engineering
            <input type="radio" name="solution-selection" id="ent" value="Web / Software Engineering" />
        </label>
        <label htmlFor="str">Digital Strategy Consulting
            <input type="radio" name="solution-selection" id="str" value="Digital Strategy Consulting" />
        </label>
        <label htmlFor="gen">None at the moment
            <input type="radio" name="solution-selection" id="gen" value="None at the moment" />
        </label>
    </fieldset>
);

const Email = () => (
    <fieldset cf-questions="Lastly, what is the best email address at which to contact you?">
        <label htmlFor="email">Lastly, what is the best email address at which to contact you?</label>
        <Input required type="email" name="email" id="email" />
    </fieldset>
);

const End = () => (
    <>
        <fieldset cf-questions={`Thank you, {name}. Tap the "Submit" button below, and an expert from our team will reach out to you in the coming days to discuss ${formQuestions.q2Selection}. We're excited to start the conversation.`}>
            <label htmlFor="confirmed-submission">Submit
                <input type="radio" name="confirmed-submission" id="confirmed-submission" value="yes" />
            </label>
        </fieldset>
    </>
);

const DataExpert = () => (
    <>
        <fieldset cf-questions="To get started, what is your first name?">
            <cf-robot-message cf-questions={`We take a humanistic approach with potential partners.`} />
            <cf-robot-message cf-questions={`In order for us to best direct you to a relevant expert from our team, please answer these four quick questions.`} />
            <label htmlFor="name">To get started, what is your first name?
                <Input required type="text" name="name" id="name" />
            </label>
        </fieldset>
        
        {(!formQuestions.q1Answered) ? (
            <IndustryQ />
        ) : (
            <input type="hidden" name="industry-selection" value={formQuestions.q1Selection} />
        )}
        
        {(!formQuestions.q2Answered) ? (
            <SolutionQ />
        ) : (
            <input type="hidden" name="solution-selection" value={formQuestions.q2Selection} />
        )}

        <Email />

        <End />
    </>
);

export default DataExpert;