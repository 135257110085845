// Imports
// ------
import React from 'react';
import Button from '@parts/Button';
import gsap from 'gsap';

// Styles
// ------
import { Jacket } from './styles';

// Component
// ------
class PolicyCursor extends React.PureComponent {
    constructor(props) {
        super(props);
        this.cursorRef = React.createRef();
    }

    componentDidMount() {
        if (window.matchMedia('(min-width: 1024px)').matches) {
            const $circle = this.cursorRef.current;
            const $wrapper = document.querySelector('.start');

            function moveCircle(e) {
                gsap.to($circle, 0.3, {
                    css: {
                        opacity: 1,
                        left: e.clientX,
                        top: e.clientY,
                    }
                });
            }

            // Container
            // ------
            $wrapper.addEventListener('mouseover', () => {
                gsap.to($circle, 0.4, { scale: 1, autoAlpha: 1 })
                $wrapper.addEventListener('mousemove', moveCircle);
            });

            $wrapper.addEventListener('mouseout', () => {
                gsap.to($circle, 0.4, { scale: 0.1, autoAlpha: 0 })
            });
        }
    }

    render() {
        return (
            <Jacket ref={this.cursorRef}>
                <Button type="button">Start a conversation</Button>
            </Jacket>
        );
    }
}

export default PolicyCursor;