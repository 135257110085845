// Imports
// ------
import React from 'react';
import Img from 'gatsby-image';
import Close from '../Close';
import { Row, Column } from '@waffl';
import { Chat } from '@states/chat';
import { observer } from 'mobx-react';

// Styles
// ------
import {
    Jacket,
    Sweater,
    MobileTitle,
    Title,
    Person,
    Picture,
    PersonPhoto
} from './styles';

// Component
// ------
const ChatHeader = () => (
    <>
        <Jacket mobile isActive={Chat.isActive}>
            <Row isExpanded isCenter>
                {Chat.requestIndividual && Chat.personImage && (
                    <Column small={3}>
                        <PersonPhoto fluid={Chat.personImage.fluid} alt={Chat.personName} />
                    </Column>
                )}
                <Column small={Chat.requestIndividual ? 6 : 9}>
                    <MobileTitle>Start a conversation</MobileTitle>
                    {Chat.requestIndividual && <Person>{Chat.personName ? `w/ ` + Chat.personName : `w/ Alex Levin`}</Person>}
                </Column>
                <Column small={3}>
                    <Close mobile />
                </Column>
            </Row>
        </Jacket>
        
        <Jacket desktop isActive={Chat.isActive}>
            <Sweater>
                <Title>Start a conversation</Title>

                {Chat.requestIndividual && <Person>{Chat.personName ? `w/ ` + Chat.personName : `w/ Alex Levin`}</Person>}
                {Chat.requestIndividual && (
                    <Picture>
                        <Img fluid={Chat.personImage.fluid} alt={Chat.personName} />
                    </Picture>
                )}
            </Sweater>
        </Jacket>
    </>
);

export default observer(ChatHeader);