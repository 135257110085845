// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Section } from '@tackl';

// Exports
// ------
export const Jacket = styled(Section)(props => css`
    transition: all 1s ${props.theme.bezzy};
    position: fixed;
    z-index: 999999;
    bottom: 0; left: 0; right: 0;
    width: 100%;
    height: ${props.theme.vh};
    overflow: hidden;
    background: ${props.theme.bc2};
    transform: translateY(${props.isActive ? `0` : `100%`});
`);

export const HeaderJacket = styled.div`
    ${breakup.large`
        height: 100%;

        > div { height: 100%; }
    `}
`;

export const DesktopJacket = styled.div(props => css`
    display: flex;
    height: ${props.theme.vh};
    
    ${breakup.large` padding: 2.4rem 0; `}
`);