// Imports
// ------
import React from 'react';
// import { Chat } from '@states/chat';
import { formQuestions } from '@states/formQuestions';

// Styles
// ------
import {
    Input
} from '../../styles';

// Component
// ------
const IndustryQ = () => (
    <fieldset cf-questions={`Thank you, {name}. Within luxury, what is your primary focus?`}>
        <label htmlFor="Fashion">Fashion &amp; Jewelry
            <input type="radio" name="industry-selection" id="Fashion" value="Fashion & Jewelry" />
        </label>
        <label htmlFor="Mobility">Mobility
            <input type="radio" name="industry-selection" id="Mobility" value="Mobility" />
        </label>
        <label htmlFor="Beauty">Beauty
            <input type="radio" name="industry-selection" id="Beauty" value="Beauty" />
        </label>
        <label htmlFor="Technology">Technology
            <input type="radio" name="industry-selection" id="Technology" value="Technology" />
        </label>
        <label htmlFor="Hospitality">Hospitality
            <input type="radio" name="industry-selection" id="Hospitality" value="Hospitality" />
        </label>
        <label htmlFor="ConsumerGoods">Consumer Goods
            <input type="radio" name="industry-selection" id="ConsumerGoods" value="Consumer Goods" />
        </label>
        <label htmlFor="Manufacturing">Manufacturing
            <input type="radio" name="industry-selection" id="Manufacturing" value="Manufacturing" />
        </label>
        <label htmlFor="FineArts">Fine Arts
            <input type="radio" name="industry-selection" id="FineArts" value="Fine Arts" />
        </label>
        <label htmlFor="GeneralInterest">General Interest
            <input type="radio" name="industry-selection" id="GeneralInterest" value="General Interest" />
        </label>
    </fieldset>
);

const SolutionQ = () => (
    <fieldset cf-questions={`And is there a particular digital solution you're looking for?`}>
        <label htmlFor="exp">Experience &amp; Product Design (UX/UI)
            <input type="radio" name="solution-selection" id="exp" value="Experience and Product Strategy" />
        </label>
        <label htmlFor="ent">Web / Software Engineering
            <input type="radio" name="solution-selection" id="ent" value="Web / Software Engineering" />
        </label>
        <label htmlFor="str">Digital Strategy Consulting
            <input type="radio" name="solution-selection" id="str" value="Digital Strategy Consulting" />
        </label>
        <label htmlFor="gen">Open to Suggestions
            <input type="radio" name="solution-selection" id="gen" value="Open to Suggestions" />
        </label>
    </fieldset>
);

const Email = () => (
    <fieldset cf-questions="Thank you, {name}. And what is the best email address at which to contact you?">
        <label htmlFor="email">Thank you, {name}. And what is the best email address at which to contact you?</label>
        <Input required type="email" name="email" id="email" />
    </fieldset>
);

const End = () => (
    <>
        <fieldset cf-questions={`Great. Tap the "Submit" button below, and an expert from our team will reach out to you in the coming days to discuss our capabilities as they pertain to your needs. We're excited to start the conversation.`}>
            <label htmlFor="confirmed-submission">Submit
                <input type="radio" name="confirmed-submission" id="confirmed-submission" value="yes" />
            </label>
        </fieldset>
    </>
);

const DataDeck = () => (
    <>
        <fieldset cf-questions="To get started, what is your first name?">
            <cf-robot-message cf-questions={`We're happy to share a capabilities deck to give you a better sense of Liquid Crystal in the context of your specific needs.`} />
            <label htmlFor="name">To get started, what is your first name?
                <Input required type="text" name="name" id="name" />
            </label>
        </fieldset>
        
        {(!formQuestions.q1Answered) ? (
            <IndustryQ />
        ) : (
            <input type="hidden" name="industry-selection" value={formQuestions.q1Selection} />
        )}
        
        {(!formQuestions.q2Answered) ? (
            <SolutionQ />
        ) : (
            <input type="hidden" name="solution-selection" value={formQuestions.q2Selection} />
        )}

        <Email />

        <End />
    </>
);

export default DataDeck;