// Imports
// ------
import React from 'react';
import { Chat } from '@states/chat';

// Styles
// ------
import {
    Jacket,
    Close
} from './styles';

// Component
// ------
const CloseButton = ({ mobile, desktop }) => {
    function handleModal() {
        Chat.isActive = !Chat.isActive;
        Chat.requestDeck = false;
        Chat.requestProposal = false;
        Chat.requestExpert = false;
        Chat.requestConversation = false;
        Chat.requestIndividual = false;
        Chat.personName = ``;
        Chat.personImage = ``;
    }

    return (
        <Jacket isMobile={mobile} isDesktop={desktop}>
            <Close
                onClick={() => handleModal()}
                isActive={Chat.isActive}
                isMobile={mobile}
                isDesktop={desktop}
                aria="Close Button"
            />
        </Jacket>
    )
}

export default CloseButton;