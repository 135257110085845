// Imports
// ------
import React from 'react';
import { Row, Column } from '@waffl';
import { observer } from 'mobx-react';
import { lightDarkMode } from '@states/darkmode';
import { themeStyles } from '@theme';

// Styles
// ------
import {
    Jacket,
    LinkList,
    LinkItem,
    Link,
    Copy
} from './styles';

// Component
// ------
const PostFooter = ({ legals }) => {
    const d = new Date().getFullYear();

    return (
        <Jacket>
            <Row isExpanded>
                <Column small={12} medium={6}>
                    <LinkList>
                        {legals.map((block, i) => (
                            <LinkItem key={"link-" + i} isDark={lightDarkMode.isDark}>
                                <Link cover direction="up" bg={lightDarkMode.isDark ? themeStyles.bc2 : themeStyles.bc1} to={'/' + block.node.slug}>{block.node.title}</Link>
                            </LinkItem>
                        ))}
                    </LinkList>
                </Column>

                <Column small={12} medium={6}>
                    <Copy>&copy; {d} Liquid Crystal. A <a href="https://levinriegner.com/" target="_blank" rel="noopener noreferrer">Levin &amp; Riegner</a> company.</Copy>
                    <Copy>By using this website you consent to the usage of cookies</Copy>
                </Column>
            </Row>
        </Jacket>
    );
}

export default observer(PostFooter);