// Imports
// ------
import React from 'react';
import PreFooter from './PreFooter';
import PostFooter from './PostFooter';


// Styles
// ------
import {
    Jacket
} from './styles';

// Component
// ------
const Footer = ({ legals, blurb, locations }) => (
    <Jacket>
        <PreFooter blurb={blurb} locations={locations} />
        <PostFooter legals={legals} />
    </Jacket>
);

export default React.memo(Footer);