// Imports
// ------
import React from 'react';

// Styles
// ------
import {
    Jacket
} from './styles';

// Component
// ------
const LogoMark = () => (
    <Jacket width="40" height="41" viewBox="0 0 40 41" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M0 20.085C0 9.0445 8.95954 0.0849609 20 0.0849609C31.0405 0.0849609 40 9.0445 40 20.085C40 31.1254 31.0405 40.085 20 40.085C8.95954 40.085 0 31.1254 0 20.085ZM0.867052 20.085C0.867052 30.6341 9.45087 39.2179 20 39.2179C30.5491 39.2179 39.1329 30.6341 39.1329 20.085C39.1329 9.53583 30.5491 0.952013 20 0.952013C9.45087 0.952013 0.867052 9.53583 0.867052 20.085ZM23.7845 22.9755C25.3741 22.9755 26.5302 22.6576 27.2239 22.3686L27.4262 20.3166L26.5591 21.7328C26.0678 22.0507 24.9695 22.3397 23.958 22.3397C21.5302 22.3397 19.7672 20.2588 19.7672 16.9351C19.7672 13.9293 21.2123 12.0796 23.7556 12.0796C24.7383 12.0796 25.7788 12.3975 26.3857 12.8021L27.2239 14.2183V12.1085C26.4724 11.7617 25.2874 11.4727 23.8712 11.4727C20.3452 11.4727 18.1487 13.9582 18.1487 17.1952C18.1198 20.4611 20.4319 22.9755 23.7845 22.9755ZM14.5973 11.7617H12.2852V28.6981H27.7187V26.5016H14.5973V11.7617Z" />
    </Jacket>
)

export default LogoMark;