// Imports
// ------
import React from 'react';
import Logo from '@parts/Logo';
import Cursor from '@parts/Cursors/Footer';
import Button from '@parts/Button';
import LogoMark from '@parts/LogoMark';
import Marquee from 'react-double-marquee';
import { Row, Column } from '@waffl';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { Chat } from '@states/chat';

// Styles
// ------
import {
    Jacket,
    MarqueeJacket,
    InteractiveMarqueeJacket,
    MarqueeLink,
    Content,
    Text,
    ConversationJacket,
    LocationsJacket,
    Loc,
    MobileButtonJacket,
    ButtonFake
} from './styles';
import { console } from 'window-or-global';

// Component
// ------
const FirstMarquee = () => (
    <Content>
        <Logo />
        <Text>- LUXURY. TECHNOLOGY. DESIGN</Text>
        <Logo />
        <Text>- LUXURY. TECHNOLOGY. DESIGN</Text>
    </Content>
);

const LocationsMarquee = ({locationList}) => (
    <>
        {locationList.map((block, i) => (
            <React.Fragment key={`block-${i}`}>
                {block.__typename === `DatoCmsLocation` && (
                    <>
                        <Loc key={`location_` + block.locationName + `_` + i}>{block.locationName}</Loc>
                    </>
                )}
            </React.Fragment>
        ))}
    </>
);

const PreFooter = ({ blurb, locations }) => {
    const breakpoints = useBreakpoint();

    function handleButton() {
        Chat.isActive = !Chat.isActive;
        Chat.requestConversation = true;
    }

    return (
        <Jacket borderDouble>
            <Row isExpanded isCollapsed>
                <Column small={12}>
                    { breakpoints.medium  ? (
                        <MarqueeJacket noMargin>
                            <Marquee direction="left" speed={0.04}>
                                <FirstMarquee />
                            </Marquee>
                        </MarqueeJacket>
                    ) : ( 
                        <MarqueeJacket noMargin>
                            <Marquee direction="left" speed={0.08}>
                                <FirstMarquee />
                            </Marquee>
                        </MarqueeJacket>
                    )}
                </Column>
                
                <Column small={12}>
                    { breakpoints.medium ? (
                        <MarqueeJacket noMargin>
                            <Marquee direction="left" speed={0.02}>
                                <ConversationJacket>
                                    <Text dangerouslySetInnerHTML={{ __html: blurb }} />
                                    <LogoMark />
                                    <Text dangerouslySetInnerHTML={{ __html: blurb }} />
                                    <LogoMark />
                                </ConversationJacket>
                            </Marquee>
                        </MarqueeJacket>
                    ):(
                        <>
                            <MarqueeLink onClick={() => handleButton()} className="start">
                                <InteractiveMarqueeJacket>
                                    <Marquee direction="left" speed={0.04}>
                                        <ConversationJacket>
                                            <Text dangerouslySetInnerHTML={{ __html: blurb }} />
                                            <ButtonFake>Start a conversation</ButtonFake>
                                            <Text dangerouslySetInnerHTML={{ __html: blurb }} />
                                            <ButtonFake>Start a conversation</ButtonFake>
                                        </ConversationJacket>
                                    </Marquee>
                                </InteractiveMarqueeJacket>
                            </MarqueeLink>

                            <Cursor />
                        </>
                    )}
                </Column>

                <Column small={12}>
                    <MarqueeJacket noMargin>
                        <Marquee direction="left" speed={0.02}>
                            <LocationsJacket>
                                <LocationsMarquee locationList={locations} />
                                <LocationsMarquee locationList={locations} />
                            </LocationsJacket>
                        </Marquee>
                    </MarqueeJacket>
                </Column>
            </Row>

            {/* Mobile button */}
            {breakpoints.medium ? (
                <Row isExpanded>
                    <Column small={12}>
                        <MobileButtonJacket>
                            <Button 
                                onClick={() => handleButton()}
                                type='button'
                                extraPad
                                fluid
                                aria="Start a conversation button"
                            >Start a conversation</Button>
                        </MobileButtonJacket>
                    </Column>
                </Row>
            ) : null }
        </Jacket>
    )
}

export default PreFooter;