// Imports
// ------
import styled from 'styled-components';

// Exports
// ------
export const Jacket = styled.svg`
    fill: ${props => props.theme.bc1};
    width: 4rem;
    height: 4rem;
`;