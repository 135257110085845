// Imports
// ------
import { observable } from 'mobx';

// Exports
export const formQuestions = observable({
    q1Selection: "General Interest",
    q1Answered: false,

    q2Selection: "",
    q2Answered: false,
});
