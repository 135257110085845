// Imports
// ------
import styled, { css } from 'styled-components';
import { breakup, Div, Em } from '@tackl';

// Exports
// ------
export const Jacket = styled(Div)` padding: 3.6rem 0; `;

const sharedMarquee = css`
    transition: all 1s ease;
    position: relative;
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow-y: visible;
    margin-bottom: ${props => props.noMargin ? `0` : `3.6rem`};
`;

export const MarqueeJacket = styled.div`
    ${sharedMarquee}
`;

export const Content = styled.div`
    display: inline-block;

    svg {
        margin-left: 12rem;
        margin-right: 2rem;
        width: auto;
        height: 3vh;

        ${breakup.medium` height: 2vh; `}
        ${breakup.large` height: 7rem; `}
    }
`;

export const Text = styled.span`
    transition: all 0.3s ease;
    display: inline;
    font-family: ${props => props.theme.heading};
    font-weight: 200;
    font-size: 4vh;
    color: ${props => props.theme.bc1};

    ${breakup.medium` font-size: 3.6vw; `}
    ${breakup.large` font-size: 9.5rem; `}
`;

export const ButtonFake = styled.span`
    position: relative;
    display: inline-block;
    border: 1px solid ${(props) => props.theme.bc1};
    border-radius: 2.4rem;
    background: ${(props) => props.theme.bc2};
    padding: 1.2rem 2.4rem;
    text-transform: uppercase;
    font-family: ${props => props.theme.body};
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: ${(props) => props.theme.bc1};
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out,
        border-color 0.3s ease-in-out, border 0.3s ease-in-out;
`;

export const ConversationJacket = styled.div`
    transition: all 1s ease;
    display: inline-flex;
    padding: 3.6rem 0;
    align-items: center;

    ${Text} {
        display: inline-block;
        margin-right: 3.6rem !important;
        max-width: 60rem;
        white-space: normal;

        font-size: 1.4rem;
        line-height: 2.4rem;

        ${breakup.medium`
            font-size: 2rem;
            line-height: 2.4rem;
        `}

        ${breakup.large`
            width: 55rem;
            max-width: 100%;
        `}

        em {
            font-family: ${props => props.theme.serif};
            font-style: italic;
            font-weight: 501;
        }
    }

    svg {
        position: relative;
        display: inline-block;
        margin-right: 3.6rem;
        min-width: 4rem;
    }

    ${ButtonFake} { margin-right: 3.6rem !important; }
`;

export const InteractiveMarqueeJacket = styled.div`
    ${sharedMarquee}
    margin: 0;

    span { margin: 0 !important; }

    &:hover {
        ${ConversationJacket} {
            pointer-events: none;
            filter: blur(1.2rem);
        }
    }
`;

export const MarqueeLink = styled.div`
    position: relative;
    display: block;
    background: none;
`;

export const LocationsJacket = styled.div`
    display: inline-flex;
    align-items: center;
`;

export const Loc = styled(Em)`
    transition: all 0.3s ease-in-out;
    margin: 0 2.4rem;
    color: ${props => props.theme.bc1};
    opacity: 0.4;
`;

export const MobileButtonJacket = styled.div`
    margin-top: 2.4rem;
`;