// Imports
// ------
import React, { useEffect } from 'react';
import Body from '@utils/bodylock';
import Header from './Header';
import Close from './Close';
import ChatWindow from './ChatWindow';
import { Row, Column } from '@waffl';
import { Chat } from '@states/chat';
import { observer } from 'mobx-react';

// Styles
// ------
import {
    Jacket,
    HeaderJacket,
    DesktopJacket
} from './styles';

// Component
// ------
const ChatModal = () => {
    useEffect(() => {
        if(Chat.isActive) {
            Body.lock();
        } else {
            Body.unlock();
        }
    });

    return (
        <Jacket isActive={Chat.isActive}>
            <DesktopJacket>
                <Row isExpanded isEqual>
                    <Column small={12}>
                        <Row isExpanded isCollapsed isEqual>
                            <Column small={12} large={4}>
                                <HeaderJacket>
                                    <Header />
                                </HeaderJacket>
                            </Column>
                            
                            <Column small={12} large={8}>
                                <Close desktop />

                                <ChatWindow />
                            </Column>
                        </Row>
                    </Column>
                </Row>
            </DesktopJacket>
        </Jacket>
    );
}

export default observer(ChatModal);