// Imports
// ------
import styled, { css } from 'styled-components';
import Img from 'gatsby-image';
import { breakup, breakdown, H2, Em } from '@tackl';

// Exports
// ------
export const Jacket = styled.div(props => css`
    display: none;
    
    ${props.mobile && css`
        ${breakdown.medium`
            transition: all 1s ${props.theme.bezzy};
            position: relative;
            z-index: 1;
            display: block;
            padding: 1.2rem 0;
            background: ${props.theme.bc2};
            
            opacity: ${props.isActive ? 1 : 0};
            transform: translateY(${props.isActive ? `0` : `-100%`});
            transition-delay: .8s;

            &:before {
                content: '';
                position: absolute;
                bottom: 0; left: 1.2rem; right: 1.2rem;
                height: 1px;
                background: ${props.theme.bc1};
            }
        `}
    `}

    ${props.desktop && css`
        ${breakup.large`
            position: relative;
            display: block;
            height: 100%;
            padding: 2.4rem 2.4rem 2.4rem 0;
            border-top: 1px solid ${props.theme.bc1};
            border-bottom: 1px solid ${props.theme.bc1};
        `}
    `}
`);

export const Sweater = styled.div`
    position: relative;
    display: block;
    height: 100%;
    overflow: hidden;
`;

export const MobileTitle = styled(Em)`
    display: block;
`;

export const Title = styled(H2)`
    display: block;
    margin: 0;
`;

export const Person = styled(H2)(props => css`
    display: block;
    margin: 0;

    color: ${props.theme.bc1};
    font-family: ${props.theme.serif};
    font-size: 2rem;
    font-style: italic;
    font-weight: normal;
    text-transform: none;
    opacity: 0.6;

    ${breakup.large`
        margin-bottom: 2.4rem;
    `}
`);

export const Picture = styled.div`
    position: relative;
    display: block;
    width: 100%;

    .gatsby-image-wrapper {
        position: absolute !important;
        top: 0; left: 0; right: 0;
    }
`;

export const PersonPhoto = styled(Img)`
    width: 100%;
    height: 6rem;
    object-fit: cover;
`;