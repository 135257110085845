// Imports
// ------
import styled, { css } from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { breakup, Div, Em, List, ListItem } from '@tackl';

// Exports
// ------
export const Jacket = styled(Div)` padding: 3.6rem 0; `;

export const LinkList = styled(List)``;

export const Link = styled(AniLink)(props => css`
    position: relative;
    display: inline-block;

    font-family: ${props.theme.body};
    font-size: 1.4rem;
    text-transform: uppercase;
    background: ${props.theme.bc2};
    color: ${props.theme.bc1};

    &:hover {
        cursor: pointer;
        &:after { transform: scaleY(1.1); }
    }

    &:after {
        content: '';
        position: absolute;
        z-index: 3;
        top: 0; left: 0; right: 0; bottom: 0;
        background: ${props.theme.bc2};
        mix-blend-mode: difference;
        transform: scaleY(0);
        transform-origin: center bottom;
        transition: all .25s ease;
    }
`);

export const Copy = styled(Em)(props => css`
    opacity: 0.4;
    text-align: center;

    ${breakup.medium` text-align: right; `}

    a {
        position: relative;
        display: inline-block;

        &:hover {
            text-decoration: underline;
        }
    }
`);


export const LinkItem = styled(ListItem)`
    display: inline-block;
    width: 50%;
    text-align: center;
    margin-bottom: 2.4rem;

    ${breakup.medium` text-align: left; `}
    
    ${breakup.large`
        width: auto;
        margin-right: 2.4rem;
    `}


    ${props => props.isDark ? `
        ${Link} {
            &:after{ background: ${props.theme.bc1}; }
        }
    ` : `
        ${Link} {
            &:after{ background: ${props.theme.bc2}; }
        }
    `}
`;