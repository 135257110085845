// Imports
// ------
import React from 'react';
// import { Chat } from '@states/chat';
// import { formQuestions } from '@states/formQuestions';

// Styles
// ------
import {
    Input
} from '../../styles';

// Component
// ------
const Email = () => (
    <fieldset cf-questions="Lastly, what is the best email address at which to contact you?">
        <label htmlFor="email">Lastly, what is the best email address at which to contact you?</label>
        <Input required type="email" name="email" id="email" />
    </fieldset>
);

const End = () => (
    <>
        <fieldset cf-questions={`Thank you, {name}. Tap the "Submit" button below, and an expert from our team will reach out to you in the coming days to discuss your project. We're excited to start the conversation.`}>
            <label htmlFor="confirmed-submission">Submit
                <input type="radio" name="confirmed-submission" id="confirmed-submission" value="yes" />
            </label>
        </fieldset>
    </>
);

const DataProposal = () => (
    <>
        <fieldset cf-questions="To get started, what is your first name?">
            <cf-robot-message cf-questions={`We would love to hear about your project and the partnership you're looking for.`} />
            <label htmlFor="name">To get started, what is your first name?
                <Input required type="text" name="name" id="name" />
            </label>
        </fieldset>
        
        <fieldset cf-questions="We can, of course, discuss in more detail in a follow up conversation.">
            <cf-robot-message cf-questions={`Thank you, {name}.`} />
            <cf-robot-message cf-questions={`Please tell us about your project in as much or as little detail as you would like. We respect your privacy and the sensitivity of your information, so please only share what you are comfortable sharing here.`} />
            <label htmlFor="project-detail">We can, of course, discuss in more detail in a follow up conversation.
                <Input required type="text" name="project-detail" id="project-detail" />
            </label>
        </fieldset>

        <Email />

        <End />
    </>
);

export default DataProposal;