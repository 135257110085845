// Imports
// ------
import React from 'react';
import { formQuestions } from '@states/formQuestions';
import { observer } from 'mobx-react';

// Styles
// ------
import {
    Input
} from '../../styles';

// Component
// ------
const Email = () => (
    <fieldset cf-questions="Lastly, what is the best email address at which to contact you?">
        <label htmlFor="email">Lastly, what is the best email address at which to contact you?</label>
        <Input required type="email" name="email" id="email" />
    </fieldset>
);

const End = () => (
    <>
        <Email />

        <fieldset cf-questions={`Thank you, {name}. Tap the "Submit" button below, and an expert from our team will reach out to you in the coming days. We're excited to start the conversation.`}>
            <label htmlFor="confirmed-submission">Submit
                <input type="radio" name="confirmed-submission" id="confirmed-submission" value="yes" />
            </label>
        </fieldset>
    </>
);

const BothAnsweredEnd = () => (
    <>
        <fieldset cf-questions="Thank you, {name}. What is the best email address at which to contact you?">
            <label htmlFor="email">Thank you, {name}. What is the best email address at which to contact you?</label>
            <Input required type="email" name="email" id="email" />
        </fieldset>

        <fieldset cf-questions={`Great. Tap the "Submit" button below, and an expert from our team will reach out to you in the coming days to discuss ${formQuestions.q2Selection}. We're excited to start the conversation.`}>
            <label htmlFor="confirmed-submission">Submit
                <input type="radio" name="confirmed-submission" id="confirmed-submission" value="yes" />
            </label>
        </fieldset>
    </>
);

const GlobalQ1 = () => (
    <fieldset cf-questions={`Thank you, {name}. Within luxury, what is your primary focus?`}>
        <label htmlFor="Fashion">Fashion &amp; Jewelry
            <input type="radio" name="industry-selection" id="Fashion" value="Fashion & Jewelry" />
        </label>
        <label htmlFor="Mobility">Mobility
            <input type="radio" name="industry-selection" id="Mobility" value="Mobility" />
        </label>
        <label htmlFor="Beauty">Beauty
            <input type="radio" name="industry-selection" id="Beauty" value="Beauty" />
        </label>
        <label htmlFor="Technology">Technology
            <input type="radio" name="industry-selection" id="Technology" value="Technology" />
        </label>
        <label htmlFor="Hospitality">Hospitality
            <input type="radio" name="industry-selection" id="Hospitality" value="Hospitality" />
        </label>
        <label htmlFor="ConsumerGoods">Consumer Goods
            <input type="radio" name="industry-selection" id="ConsumerGoods" value="Consumer Goods" />
        </label>
        <label htmlFor="Manufacturing">Manufacturing
            <input type="radio" name="industry-selection" id="Manufacturing" value="Manufacturing" />
        </label>
        <label htmlFor="FineArts">Fine Arts
            <input type="radio" name="industry-selection" id="FineArts" value="Fine Arts" />
        </label>
        <label htmlFor="GeneralInterest">General Interest
            <input type="radio" name="industry-selection" id="GeneralInterest" value="General Interest" />
        </label>
    </fieldset>
);

const GlobalQ2 = ({ first, second, third  }) => (
    <>
        {!third ? (
            <>
                <fieldset cf-questions={`And what type of digital solution is most important to you?`}>
                    <label htmlFor="exp">Experience &amp; Product Design (UX/UI)
                        <input type="radio" name="solution-selection" id="exp" value="Experience and Product Design (UX/UI)" />
                    </label>
                    <label htmlFor="ent">Web / Software Engineering
                        <input type="radio" name="solution-selection" id="ent" value="Web / Software Engineering" />
                    </label>
                    <label htmlFor="str">Digital Strategy Consulting
                        <input type="radio" name="solution-selection" id="str" value="Digital Strategy Consulting" />
                    </label>
                    {first && (
                        <label htmlFor="none">None at the moment
                            <input type="radio" name="solution-selection" id="none" value="Not at the moment" />
                        </label>
                    )}

                    {second && (
                        <label htmlFor="gen">General Enquiry
                            <input type="radio" name="solution-selection" id="gen" value="General Enquiry" />
                        </label>
                    )}
                </fieldset>

                <End />
            </>
        ) : (
            <>
                <fieldset cf-questions={`Within the {industry-selection} industry, we understand you're interested in ${formQuestions.q2Answered ? formQuestions.q2Selection : "{solution-selection}"}. Is that correct, or would you like to discuss other solutions?`}>
                    <label htmlFor="correct">That's Correct
                        <input type="radio" name="chooseagain" id="correct" value="That's Correct" />
                    </label>

                    <label htmlFor="chooseAnother">Choose Another Digital Solution
                        <input type="radio" name="chooseagain" id="chooseAnother" value="Choose Another Digital Solution" />
                    </label>
                </fieldset>

                <fieldset cf-questions={`Within {industry-selection}, is there a particular digital solution you're looking for?`}>
                    <label htmlFor="exp2">Experience &amp; Product Strategy
                        <input type="radio" name="solution-selection" id="exp2" value="Experience and Product Strategy" cf-conditional-chooseagain="Choose Another Digital Solution" />
                    </label>
                    <label htmlFor="ent2">Web / Software Engineering
                        <input type="radio" name="solution-selection" id="ent2" value="Web / Software Engineering" cf-conditional-chooseagain="Choose Another Digital Solution" />
                    </label>
                    <label htmlFor="str2">Digital Strategy Consulting
                        <input type="radio" name="solution-selection" id="str2" value="Digital Strategy Consulting" cf-conditional-chooseagain="Choose Another Digital Solution" />
                    </label>
                    <label htmlFor="openToSuggestions2">Open to Suggestions
                        <input type="radio" name="solution-selection" id="openToSuggestions2" value="Open to Suggestions" cf-conditional-chooseagain="Choose Another Digital Solution" />
                    </label>
                </fieldset>

                <End />
            </>
        )}
    </>
);

const DataConversation = () => {
    return (
        <>
            {/* NOTE Question 1 NOT Answered, Question 2 NOT answered - FIRST TAB */}
            {(!formQuestions.q1Answered && !formQuestions.q2Answered) && (
                <>
                    <fieldset cf-questions="To get started, what is your first name?">
                        <cf-robot-message cf-questions="We look forward to speaking with you." />
                        <cf-robot-message cf-questions="Please answer the following simple questions in order for us to direct you to a relevant expert from our team." />
                        <label htmlFor="name">To get started, what is your first name?</label>
                        <Input required type="text" name="name" id="name" />
                    </fieldset>

                    <GlobalQ1 />
                    <GlobalQ2 name="{name}" first />
                </>
            )}

            {/* NOTE Question 1 Answered, Question 2 Not answered - SECOND TAB */}
            {(formQuestions.q1Answered && !formQuestions.q2Answered) && (
                <>
                    <fieldset cf-questions="To get started, what is your first name?">
                        <cf-robot-message cf-questions="We take a humanistic approach with potential partners." />
                        <cf-robot-message cf-questions="We saw that you noted your industry focus. In order for us to better direct you to a relevant expert from our team, please answer a few quick questions." />
                        <label htmlFor="name">To get started, what is your first name?</label>
                        <Input required type="text" name="name" id="name" />
                    </fieldset>
                    
                    <input type="hidden" name="industry-selection" value={formQuestions.q1Selection} />

                    <GlobalQ2 name="{name}" first />
                </>
            )}

            {/* NOTE Question 1 NOT Answered, Question 2 answered - THIRD TAB */}
            {(!formQuestions.q1Answered && formQuestions.q2Answered) && (
                <>
                    <fieldset cf-questions="To get started, what is your first name?">
                        <cf-robot-message cf-questions="We take a humanistic approach with potential partners." />
                        <cf-robot-message cf-questions="We saw that you indicated an interest in a particular digital solution. In order for us to better direct you to a relevant expert from our team, please answer a few quick questions." />
                        <label htmlFor="name">To get started, what is your first name?</label>
                        <Input required type="text" name="name" id="name" />
                    </fieldset>

                    <GlobalQ1 />
                    <GlobalQ2 third />
                </>
            )}

            {/* NOTE Question 1 Answered, Question 2 answered - FOURTH TAB */}
            {(formQuestions.q1Answered && formQuestions.q2Answered) && (
                <>
                    <fieldset cf-questions="To get started, what is your first name?">
                        <cf-robot-message cf-questions="We take a humanistic approach with potential partners." />
                        <cf-robot-message cf-questions="We saw that you noted your industry focus and an interest in particular digital solutions. In order for us to better direct you to a relevant expert from our team, please answer a few quick questions." />
                        <label htmlFor="name">To get started, what is your first name?</label>
                        <Input required type="text" name="name" id="name" />
                    </fieldset>

                    <input type="hidden" name="industry-selection" value={formQuestions.q1Selection} />
                    <input type="hidden" name="solution-selection" value={formQuestions.q2Selection} />

                    <BothAnsweredEnd />
                </>
            )}
        </>
    );
}

export default observer(DataConversation);